.terms {
  margin: auto;
  margin-top: 50px;

  .terms-container {
    overflow-y: auto;
    max-height: 60vh;
    margin-right: 20px;

    h1,
    h2 {
      color: #478ff3;
    }

    h1 {
      margin-bottom: 30px;
    }
    h2 {
      margin-bottom: 20px;
    }
    ul {
      list-style-type: decimal;
      li {
        margin-top: 20px;
        &::marker {
          font-weight: bold;
        }
      }
    }
  }

  .image-container {
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      max-width: 441px;
      padding: 0;
    }

    .btn-redirect-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;

      .btn-homepage {
        display: flex;
        justify-content: center;
        padding: 5px;
        border-radius: 30px;
        text-decoration: none;
        border: none;
        background-color: #478ff3;
        color: #fff;
        width: 50%;
        margin-bottom: 30px;
      }
    }
  }

    @media (max-width: 768px) {
    .terms-container {
      overflow-y: visible;
      max-height: none;
    }
  }
}
