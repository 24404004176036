@import '../../variables/color-palette.scss';

.cookie-container {
  display: flex;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  z-index: 999999;
  top: 40px;
  left: 0;
  right: 0;
  border-radius: 5rem;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 43px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 10px 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-color: #000;
  max-width: 817px;
  backdrop-filter: blur(75px);
  .cookie-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding-left: 43px;
    padding-right: 13px;
    padding-top: 20px;
  }

  .cookie-button {
    bottom: 0;
    margin: auto;
    padding: 1%;
    max-width: 160px;
    margin-right: 30px;
    margin-left: 25px;
    background-color: #476FE3;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    border: 0;
    width: 100%;
    height: 100%;
    font-weight: 700;
    color: white;
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .cookie-container {
    display: block;
    margin-left: 12px;
    margin-right: 12px;
    padding-right: 85px;
    .cookie-text {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      padding-left: 25px;
      padding-right: 0px;
      width: 100%;
    }
    .cookie-button {
      max-width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
