@import 'bootstrap/dist/css/bootstrap.min.css';
@import './variables/color-palette.scss';

body {
  font-family: 'Trebuchet MS', sans-serif !important;
  overflow: hidden;
}

.logo {
  margin: 0 auto;
  margin-top: 19vh;
}

.color-primary {
  color: $primaryColor;
}

.color-secondary {
  color: $secondaryColor;
}

.toast-message {
  background-color: $primaryColor;
  color: white;
}

.op-3 {
  opacity: 0.3;
}

::-webkit-scrollbar {
  width: 10px;
  margin-left: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #d9d9d9;
  width: 10px;
  height: 100px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

@media (max-height: '900px') {
  body.height-based-scrollable {
    overflow-y: auto;
  }
}
