@import '../variables/color-palette.scss';

.signin {
  .main-row {
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 1033px;
    border-radius: 50px;
    min-height: 580px;
    margin: auto;
    .illustration-col {
      min-height: 580px;
      position: relative;
      background-color: white;
      height: 100%;
      border-radius: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .signin-col {
      padding: 2%;
      .header {
        font-weight: 700;
        font-size: 25px;
        line-height: 29px;
        color: white;
        margin-top: 25px;
      }

      .sign-buttons {
        margin-top: 20px;
        padding-bottom: 20px;
        min-width: 100%;
      }
      .signup-button {
        margin-top: 20px;
        background: white;
        border-radius: 30px;
        width: 100%;
        height: 40px;
        color: $darkColor;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        border: none;
      }

      .option {
        align-items: center;
        display: flex;
        height: 40px;
        position: relative;
        align-items: center;
        cursor: pointer;
        p {
          display: flex;
          text-align: center;
          padding-top: 12px;
          padding-left: 4px;
          color: $darkColor;
          margin-top: 2px;
          margin-right: auto;
          margin-left: 15px;
        }
        img {
          margin-left: auto;
          margin-right: 15px;
        }
      }

      .signin-button {
        margin-top: 20px;
        background: $primaryColor;
        border-radius: 30px;
        width: 100%;
        height: 40px;
        color: white;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        border: none;
      }

      .signin-form {
        margin-top: 33px;

        .form-checkbox {
          margin-top: 20px;
          display: flex;
          align-items: center;
          input {
            margin-right: 10px;
            accent-color: $primaryColor;
            width: 15px;
            height: 15px;
          }
          label {
            color: white;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
          }
        }

        .signin-button {
          margin-top: 40px;
          background: white;
          border-radius: 30px;
          width: 210px;
          height: 40px;
          color: $darkColor;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          border: none;
        }

        .signup-button {
          margin-top: 40px;
          background: $primaryColor;
          border-radius: 30px;
          width: 210px;
          height: 40px;
          color: white;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          border: none;
        }
      }
    }
  }
}
