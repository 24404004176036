@import '../variables/color-palette.scss';

.download {
  .main-row {
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 1033px;
    border-radius: 50px;
    min-height: 580px;
    margin: auto;
    .introduction-col {
      min-height: 580px;
      position: relative;
      background-color: white;
      height: 100%;
      border-radius: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .illustration {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 100%;
          max-width: 523px;
        }
        .illustration-header {
          margin-top: 20px;
        }

        .illustration-text {
          margin-top: 11px;
        }
      }
    }
  }

  .sender {
    margin-top: 30px;
    width: 100%;
    color: $darkColor;
    min-height: 40px;
    border-radius: 30px;
    background-color: white;
    border: 1px solid #d9d9d9;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 4px;
    overflow: hidden;
    font-size: 12px;
    span {
      color: $primaryColor;
    }
  }

  .button {
    width: 100%;
    height: 40px;
    margin-top: 14px;
    border-radius: 20px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    border: 0px;
    background: $primaryColor;
  }

  .download-col {
    height: 580px;
    .files {
      margin-top: 25px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 65%;
      width: 100%;
      .file {
        position: relative;
        height: fit-content;
        width: 97%;
        border: 1.5px solid var(--light-gray, #d9d9d9);
        background: #fff;
        display: flex;
        border-radius: 12px;
        align-items: flex-start;
        .preview {
          margin: auto;
          display: flex;
          justify-content: center; /* Horizontal center alignment */
          align-items: center; /* Vertical center alignment */
          height: 100%;
          width: 71px;
          max-width: 71px;
          margin-top: auto;
        }

        .detail {
          height: 100%;
          width: 174px;
          overflow: hidden;
          margin: auto;
          display: flex;
          flex-direction: column;
          font-size: 13px;

          .name {
            color: $darkColor;
            line-height: 16px;
            margin-bottom: 2px;
            margin-top: 3px;
          }

          .size {
            color: $secondaryColor;
            line-height: 16px;
          }
        }

        .download-button {
          margin: auto;
          display: flex;
          cursor: pointer;
          justify-content: center; /* Horizontal center alignment */
          align-items: center; /* Vertical center alignment */
          height: 100%;
          width: 50px;
          max-width: 50px;
          margin-top: auto;
        }
      }

      .file:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  .password-col {
    height: 100%;
    padding-right: 35px;
    padding-left: 35px;

    .password-icon {
      display: flex;
      justify-content: center;
      margin-top: 81px;
    }

    h1 {
      text-align: center;
      color: white;
      width: 213px;
      margin: auto;
      font-size: 20px;
      margin-top: 20px;
    }

    .password-field {
      margin-top: 33px;
      input {
        width: 213px;
        margin: auto;
      }

      .validation-error {
        margin-right: 20px;
      }
    }
    .button {
      margin-top: 85px;
      height: 40px;
      margin-bottom: 5%;
    }
  }
}
