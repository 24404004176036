@import '../variables/color-palette.scss';
@import '../variables/breakpoints.scss';

.transferpage {
  .main-row {
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 1033px;
    border-radius: 50px;
    min-height: 580px;
    margin: auto;
    .preview-col {
      position: relative;
      background-color: white;
      height: 580px;
      display: flex;
      max-height: 580px;
      flex-direction: column;
      border-radius: 50px;
      .progress-bar {
        position: relative;
        margin-top: 6%;
        margin-left: 3.5%;
        margin-right: 3.5%;
        border-radius: 11px;
        height: 23px;
        .stripe {
          position: absolute;
          width: 100%;
          height: 100%;
          background: repeating-linear-gradient(
            120deg,
            rgba(217, 217, 217, 0.5),
            rgba(217, 217, 217, 0.5) 10px,
            rgba(136, 146, 164, 0.5) 10px,
            rgba(136, 146, 164, 0.5) 20px
          );
          animation: move-stripe 5s linear infinite;
        }

        .files-stats {
          display: flex;
          background-color: white;
          border: 0.5px solid #dadada;
          box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.15);
          border-radius: 11.5px;
          height: 100%;
          position: relative;
          align-items: center;
          overflow: hidden;
        }
        .files-stats::before {
          content: '';
          background-color: $primaryColor;
          color: white;
          width: var(--files-stats-width);
          height: 100%;
          position: absolute;
          border-radius: 11.5px;
          top: 0;
          left: 0;
        }

        .files-stats > span {
          color: $secondaryColor;
          z-index: 1;
        }

        .file-count {
          left: 0;
          margin-right: auto;
          font-size: 16px;
          font-weight: 700;
        }

        .file-size {
          right: 0;
          margin-left: auto;
          font-size: 12px;
          font-weight: 700;
        }

        .files-stats-over {
          background: transparent;
          position: absolute;
          overflow: hidden;
          width: var(--files-stats-over-width);
          height: 100%;
          font-weight: 700;
          color: white;
          z-index: 2;
        }

        .files-stats-over-progress {
          display: flex;
          position: absolute;
          background-color: transparent;
          width: var(--files-stats-over-progress-width);
          align-items: center;
        }
        .sign-up {
          position: absolute;
          width: fit-content;
          right: 0;
          background: #d9d9d9;
          margin-right: 3px;
          border: 0.5px solid #dadada;
          border-radius: 9px;
          font-size: 12px;
          line-height: 14px;
          color: $secondaryColor;
          z-index: 2;
          span {
            text-decoration: underline;
          }
        }
      }

      .add-more-bottom {
        position: absolute;
        display: block;
        cursor: pointer;
        justify-content: flex-end;
        background: transparent;
        width: fit-content;
        bottom: 0;
        right: 0;
        margin-bottom: 10px;
        margin-right: 10px;
        z-index: 20;
      }

      .files {
        max-height: 83%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 20px;
        .file {
          flex: 50%;
          max-width: 46%;
          height: 128.4px;
          margin: 2%;
          border: 1.035px solid #d9d9d9;
          background: #ffffff;
          box-sizing: border-box;
          border-radius: 10.35px;
          object-fit: contain;
          .file-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 10%;
          }
          .file-remove {
            position: absolute;
            top: 0;
            right: 0;
            background: transparent;
            border: none;
            cursor: pointer;
            z-index: 10;
            margin-right: -6%;
            margin-top: -6%;
          }
          .file-preview {
            position: relative;
            left: -6%;
            width: 112%;
            height: 100%;
            cursor: pointer;
            .file-preview-hover {
              display: flex;
              position: absolute;
              top: 0;
              left: 0;
              padding-left: 2%;
              padding-right: 2%;
              min-width: 100%;
              overflow-wrap: break-word;
              height: 100%;
              z-index: 5;
              background: rgba(220, 220, 220, 0.4);
              backdrop-filter: blur(20px);
              align-items: center;
              justify-content: center;

              .file-name-hover {
                margin: auto;
                position: fixed;
                bottom: 40%;
                font-weight: 700;
                font-size: 14px;
                text-align: center;
                max-width: 100%;
                color: $darkColor;
              }

              .file-size-hover {
                margin: auto;
                position: fixed;
                top: 60%;
                margin-top: 10px;
                font-weight: 700;
                font-size: 13px;
                line-height: 15px;
                text-align: center;
                color: $secondaryColor;
              }
            }
            img {
              object-fit: cover;
              width: 100%;
              height: 80%;
              z-index: 1;
              position: absolute;
            }
            .file-preview-overlay {
              bottom: 0;
              position: absolute;
              z-index: 2;
              width: 100%;
              height: 25%;
              border-radius: 0 0 10.35px 10.35px;
              display: flex;
              justify-content: center;
              align-items: center;
              .file-name {
                left: 0;
                margin-right: auto;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 45%;
                font-weight: 700;
                font-size: 12px;
                color: $darkColor;
                margin-top: 3%;
                margin-left: 3%;
              }
              .file-size {
                right: 0;
                white-space: nowrap;
                margin-left: auto;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 50%;
                color: #d9d9d9;
                font-size: 12px;
                margin-top: 3%;
                margin-right: 3%;
              }
            }
          }
        }

        .add-more-button {
          cursor: pointer;
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: center;
          h5 {
            color: $primaryColor;
          }
          p {
            color: $secondaryColor;
            font-size: 12px;
          }
        }
      }

      .dropzone {
        border-radius: 50px;
        p {
          margin: auto;
          font-size: 30px;
          font-weight: 700;
        }
      }
    }
    .share-col {
      margin: auto;
      padding: 0px 30px 0 30px;

      .share-option-container {
        justify-content: flex-end;
        margin: 0;
        margin-bottom: 20px;
        margin-top: 20px;

        .share-option-buttons {
          background: rgba(#d9d9d9, 0.5);
          border-radius: 30px;
          position: relative;
          display: flex;
          padding: 0;
          justify-content: space-between;
          flex-wrap: nowrap;
          button {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            padding: 5px 20px;
            background-color: transparent;
            border: none;
            white-space: nowrap;

            &.active {
              background-color: #ffffff;
              width: 50%;
              p {
                color: $darkColor;
                margin: 0;
              }

              svg {
                path {
                  fill: $darkColor;
                }
              }
            }

            p {
              color: $secondaryColor;
              margin: 0;
            }

            svg {
              path {
                fill: $secondaryColor;
              }

              margin-left: 10px;
            }
          }
        }
      }

      .custom-form-group {
        textarea::placeholder,
        .letter-count {
          color: #d9d9d9;
        }
      }

      .custom-form-group.expiration {
        margin-top: 20px;

        .keep-switch-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 20px;
          margin-top: 10px;

          .switch {
            margin-right: 10px;
          }

          > span {
            color: #ffffff;
            margin-right: 5px;
          }
        }
      }

      .custom-form-group.add-email-to {
        position: relative;

        > div {
          background-color: #ffffff;
          border-radius: 30px;
          display: flex;
          margin: 0;

          input {
            width: 85%;
          }

          .btn-add-email-to {
            padding: 0;
            border: none;
            background-color: transparent;
            svg {
              border-radius: 50px;
              width: 20px;
              height: auto;
            }
          }
        }

        .validation-error {
          right: 30px;

          .validation-text-container {
            right: -15px;
          }
        }

        .to-email-list-container {
          margin-top: 5px;
          margin-left: auto;
          max-width: 75%;
          overflow: hidden;
          padding: 3px;
          position: absolute;
          right: 0;
          bottom: -40px;
          background-color: #ffffff;
          border-radius: 30px;
          display: flex;
          margin: 0;
          cursor: pointer;

          .to-email-list {
            overflow: hidden;
            display: flex;

            .email-to {
              margin-left: -9px;
              border-radius: 30px;
              height: 25px;
              width: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ffffff;

              &:first-child {
                margin-left: 0;
              }

              span {
                margin: 0;
                font-weight: 600;
                font-size: 18px;
                max-width: 20px;
                max-height: 20px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .to-email-list-full-container {
          position: absolute;
          margin-top: 5px;
          border-radius: 20px;
          z-index: 999;
          width: 100%;
          padding: 5px 10px;
          background-color: #ffffff;
          display: none;
          flex-direction: column;

          .emails {
            width: 100%;
            overflow-y: auto;
            max-height: 300px;
            padding-right: 5px;

            > .row {
              margin: 7px 0;

              &:last-child {
                margin-bottom: 0;
              }

              .email-text {
                margin-left: 10px;
                font-weight: 700;
                font-size: 13px;
                p {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              > div {
                padding: 0;
                display: flex;
                align-items: center;
                &:first-child {
                  margin: 0;
                }

                button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 15px;
                  margin: 0;
                  background-color: transparent;
                  border: 1px solid #d9d9d9;
                  border-radius: 30px;
                  color: #d9d9d9;
                  width: 25px;
                  height: 25px;
                }
              }
            }

            .email-to {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 30px;
              width: 30px;
              margin-left: 2px;
              border-radius: 30px;
              background-color: #ffffff;
              padding: 2px;

              &:first-child {
                margin-left: 0;
              }

              span {
                margin: 0;
                font-weight: 600;
                font-size: 22px;
                max-width: 30px;
                max-height: 30px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 0;
              }
            }

            &::-webkit-scrollbar-track {
              margin-bottom: 0;
            }
            &::-webkit-scrollbar-thumb {
              -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.5);
            }
          }

          &.deactive {
            display: flex;
            -webkit-animation: scaleOut;
            -webkit-animation-duration: 0.5s;
            animation: scaleOut;
            animation-duration: 0.5s;
          }

          &.active {
            display: flex;
            -webkit-animation: scaleIn;
            -webkit-animation-duration: 1s;
            animation: scaleIn;
            animation-duration: 1s;
          }

          p {
            margin: 0;
          }

          .buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 10px;

            button {
              width: 100%;
              border-radius: 25px;
              background-color: transparent;
              font-size: 14px;
              border: 1px solid #d9d9d9;
              font-weight: 700;
            }

            .btn-remove-all {
              color: red;
            }
          }
        }
      }

      .send-container {
        margin-top: 20px;
        margin-bottom: 20px;

        > div {
          display: flex;
          justify-content: space-between;

          .password-container {
            background-color: #ffffff;
            border-radius: 30px;
            margin: 0;
            display: none;
            position: relative;
            flex-direction: row;
            padding-right: 5px;

            .custom-form-group {
              margin: 0;
              > span {
                display: none;
              }

              > input {
                width: 100%;
              }
            }

            input {
              padding-right: 5px;
              width: 70%;
            }

            button {
              padding: 0;
            }

            &.active {
              display: flex;
            }

            svg {
              margin-left: 0px;
            }

            .btn-show-password {
              background-color: transparent;
            }

            .btn-disable-password {
              padding: 0;
              border: none;
              background-color: transparent;
              margin-left: 5px;
              svg {
                border-radius: 50px;
                width: 20px;
                height: auto;
              }
            }
          }

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            padding-left: 20px;
            padding-right: 20px;
            border: none;
            white-space: nowrap;

            &.btn-add-password {
              display: none;
              &.active {
                display: flex;
              }
            }

            p {
              margin: 0;
            }

            svg {
              margin-left: 10px;
            }

            &.btn-add-password {
              background-color: #ffffff;
              color: #478ff3;
              width: 100%;
            }

            &.btn-send {
              padding-left: 40px;
              padding-right: 40px;
              background-color: #478ff3;
            }
          }
        }
      }
    }
  }
}

@keyframes move-stripe {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(46px);
  }
}

@-webkit-keyframes scaleIn {
  0% {
    -webkit-transform: scale(0);
    -webkit-transform-origin: right top;
  }
  100% {
    -webkit-transform: scale(1);
    -webkit-transform-origin: right top;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
    transform-origin: right top;
  }
  100% {
    transform: scale(1);
    transform-origin: right top;
  }
}

@-webkit-keyframes scaleOut {
  0% {
    -webkit-transform: scale(1);
    -webkit-transform-origin: left bottom;
  }
  100% {
    -webkit-transform: scale(0);
    -webkit-transform-origin: right top;
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
    transform-origin: right top;
  }
  100% {
    transform: scale(0);
    transform-origin: right top;
  }
}
