@import '../../variables/color-palette.scss';

.menu {
  list-style-type: none;
  right: 0;
  top: 0;
  position: absolute;
  background-color: white;
  margin-top: 120%;
  overflow: hidden;
  flex-direction: column;
  border: 0;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 10px 4px 6px -4px rgb(0 0 0 / 0.1);
  border-radius: 30px;
  min-width: 250px;
  padding: 5px;

  .menu-item {
    a {
      display: flex;
      align-items: center;
      margin: 5px;
      text-decoration: none;
      padding: 5px 10px;
      border-radius: 20px;

      p {
        color: black;
        margin: 0 0 0 15px;
      }
    }

    &:hover {
      path {
        fill: white;
      }
      a {
        background-color: $primaryColor;
        p {
          color: white;
        }
      }
    }
  }
}
