@import '../../variables/breakpoints.scss';

.background {
  background-color: var(--background-color);
  height: auto;
  overflow: hidden;

  .particle {
    position: absolute;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    opacity: 0.7;
    z-index: -1;
    animation: particle 60s linear infinite;
  }

  .particle1 {
    background-color: var(--particle-color);
    filter: blur(50px);
    box-shadow: 0px 0px 60px var(--particle-color);
  }

  .particle2 {
    background-color: #ffffff;
    filter: blur(60px);
    box-shadow: 0px 0px 80px #ffffff;
    animation-duration: 50s;
  }

  .particle:nth-of-type(1) {
    top: 20%;
    left: 10%;
  }

  .particle:nth-of-type(2) {
    top: 50%;
    left: 40%;
  }

  .particle:nth-of-type(3) {
    top: 70%;
    left: 70%;
  }

  .particle:nth-of-type(4) {
    top: 30%;
    left: 80%;
  }

  .particle:nth-of-type(5) {
    top: 10%;
    left: 60%;
  }

  @media (max-width: $md) {
    .particle {
      width: 50px;
      height: 50px;
    }

    .particle:nth-of-type(1) {
      top: 20%;
      left: 5%;
    }

    .particle:nth-of-type(2) {
      top: 50%;
      left: 35%;
    }

    .particle:nth-of-type(3) {
      top: 70%;
      left: 65%;
    }

    .particle:nth-of-type(4) {
      top: 30%;
      left: 75%;
    }

    .particle:nth-of-type(5) {
      top: 10%;
      left: 55%;
    }
  }
}

@media (max-width: $md) {
  .background {
    overflow: hidden;
  }
}

@media (max-width: $lg) {
  .particle {
    width: 50px;
    height: 50px;
  }
}
