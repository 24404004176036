@import '../../css/rc-slider.css';
@import '../../variables/color-palette.scss';
@import '../../variables/breakpoints.scss';

.custom-form-group {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 20px;
  .input-text {
    color: #fff;
    padding-left: 10px;
    margin-bottom: 10px;
  }
  input {
    border: solid 1px #e7e7e7;

    &.text {
      border-radius: 30px;
      outline: none;
      box-shadow: none;
      border: none;
      height: 35px;
      padding: 15px;
    }
  }
  textarea {
    border: solid 1px #e7e7e7;
    resize: none;
    border-radius: 20px;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 15px;
    padding-bottom: 25px;
    overflow-y: auto;
    scroll-padding: 25px;
  }

  .validation-tooltip {
    display: block !important;
  }

  .hyperlink-button {
    background: white;
    border: none;
    color: #d9d9d9;
    font-weight: 700;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 4px;
    margin-right: 12px;
    position: absolute;
    z-index: 2;
  }

  .validation-error {
    position: absolute;
    z-index: 99999;
    right: 10px;
    bottom: -3px;

    svg {
      bottom: 10px;
    }

    .validation-text-container {
      display: block;
      position: absolute;
      background-color: #fff;
      border-radius: 30px;
      right: -10px;
      padding: 5px 20px;
      margin-top: 10px;
      font-size: 13px;
      font-weight: 700;
      width: max-content;
      max-width: 250px;

      .up-triangle {
        position: absolute;
        top: -13px;
        right: 32px;
        transform: translateX(50%);
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 14px solid #fff;
      }

      span {
        display: block;
        color: red;
        width: fit-content;
      }
    }
  }

  .letter-count {
    position: absolute;
    bottom: 5px;
    right: 15px;
    font-size: 12px;
    margin: 0;
  }

  .slider-parent {
    display: flex;
    flex-direction: row;
    background: #fff;
    border-radius: 30px;
    padding: 5px 10px;
    position: relative;
    align-items: center;

    .col-md-3 {
      padding-left: 10px;
    }

    .rc-slider {
      height: 20px;
      display: flex;
      align-items: center;
      padding: 0;

      .rc-slider-mark {
        display: none;
      }

      .rc-slider-step {
        height: 9px;
        left: 5%;
      }

      .rc-slider-track {
        height: 9px;
        background: transparent;
      }

      .rc-slider-dot {
        background: rgba(#d9d9d9, 50%);
        border: none;
        bottom: 0;
        height: 8px;
        width: 8px;
        margin-top: 0;
        transform: translateX(-60%) !important;

        &:last-child {
          transform: translateX(-100%) !important;
        }
      }

      .rc-slider-handle {
        margin-top: 0;
        height: 20px;
        width: 20px;
        border: none;
        background: linear-gradient(0deg, #d9d9d9 0%, #fff 100%);
        opacity: 1;
        transform: translateX(0) !important;

        &.rc-slider-handle-dragging {
          border-color: transparent;
          box-shadow: none;
        }
      }

      .rc-slider-rail {
        border: solid 1px #e7e7e7;
        background: linear-gradient(90deg, #fff, #478ff3 100%);
        height: 10px;
        width: 106%;
      }
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &:before {
        position: absolute;
        content: '';
        height: 17px;
        width: 17px;
        left: 1px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(13px);
      -ms-transform: translateX(13px);
      transform: translateX(13px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 17px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  .checkbox {
    input {
      margin-top: 4px;
      position: absolute;
      margin-right: 10px;
      accent-color: $primaryColor;
      width: 15px;
      height: 15px;
    }
  }
}

@media (max-width: $md) {
  .custom-form-group {
    .slider-parent {
      .col-md-9 {
        width: 100%;
      }

      .rc-slider {
        .rc-slider-step {
          left: 2%;
        }
      }
    }
  }
}
