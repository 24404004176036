.dropzone {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  p {
    font-weight: 500;
    font-size: 50.8673px;
    line-height: 59px;
    color: #8892a4;
    margin: 0 auto;
    margin-top: 40vh;
  }
}
