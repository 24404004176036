@import '../variables/color-palette.scss';

.resetpassword {
  .main-row {
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 1033px;
    border-radius: 50px;
    min-height: 580px;
    margin: auto;

    .illustration-col {
      min-height: 580px;
      position: relative;
      background-color: white;
      height: 100%;
      border-radius: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .reset-password-col {
      .header {
        margin-top: 60px;
        .col {
          display: flex;
          align-items: center;

          h4 {
            color: white;
            margin-left: 15px;
            margin-bottom: 0;
          }
        }
      }

      .password-requirements-container {
        margin-top: 30px;
        margin-bottom: 45px;
        padding: 0 10px;

        .requirement {
          margin-top: 10px;

          span {
            margin-left: 10px;
            text-align: center;
            font-size: 13px;
            color: rgba(255, 255, 255, 1);

            &.blurred {
              color: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }

      .button-container {
        margin-top: 30px;
        margin-bottom: 5%;

        > div {
          display: flex;
          justify-content: space-between;
        }

        button {
          border-radius: 25px;
          outline: none;
          border: none;
          width: 100%;

          p {
            margin: 0;
          }

          &.btn-back {
            background-color: white;
            p {
              color: black;
            }
          }

          &.btn-save-password {
            background-color: #478ff3;
            p {
              color: white;
            }
          }
        }
      }
    }

    .reset-password-info-col {
      margin-top: 60px;
      text-align: center;
      position: relative;

      h1 {
        margin-top: 69px;
        color: #ffffff;
        font-size: 25px;
      }

      p {
        margin-top: 17px;
        color: #ffffff;
        max-width: 300px;
        margin: auto;
        font-size: 16px;
      }

      button {
        margin-top: 60px;
        background: $primaryColor;
        border-radius: 30px;
        width: 210px;
        height: 40px;
        color: white;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        border: none;
      }
    }
  }
}
