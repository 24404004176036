.view-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #d9d9d9;
    margin: 0 5px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: #2c2c2c;
    }
  }
}
