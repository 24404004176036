@import '../variables/color-palette.scss';
@import '../variables/breakpoints.scss';

.safezone {
  text-align: center;
  color: #fff;

  .illustration {
    margin-top: 100px;
    width: 100%;
    max-width: 557px;
  }

  p {
    margin: auto;
    margin-top: 34px;
    width: 377px;
    font-size: 16px;
    font-weight: 500;
  }

  .buttons {
    width: fit-content;
    margin: auto;
    margin-top: 34px;

    .button {
      width: 222px;
      height: 40px;
      border-radius: 20px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    }

    .homepage-button {
      background: #478ff3;
    }
  }
}

@media (max-width: $md) {
  .safezone {
    padding: 0 15px;
    p {
      width: auto;
      font-size: 14px;
    }

    .illustration {
      width: 80% !important;
    }
  }
}
