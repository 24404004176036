@import '../../variables/breakpoints.scss';

.navbar {
  margin-right: 1vw;
  margin-top: 4vh;
  margin-bottom: 70px;
  z-index: 99;

  > .row {
    width: 100%;

    > .col {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    > .header-logo {
      justify-content: flex-start;
      margin-left: 50px;
      cursor: pointer;
    }
  }

  @media (max-width: $md) {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 40px;

    &.minimized {
      opacity: 0;
      transition: 1s;
      z-index: -1;
    }

    &.nminimized {
      transform: scale(1);
      opacity: 1;
      transition: 1s;
    }

    > .row {
      margin: 0;
    }

    .logo-button {
      svg {
        width: 150px;
      }
    }

    .account-header {
      min-width: 120px;
      margin-right: 10px;
      text-align: start;

      p {
        font-size: 16px;
      }

      > p {
        text-align: end;
      }

      .actions {
        align-items: center;
        justify-content: flex-end;

        p {
          line-height: var(--bs-body-line-height);
        }
        p,
        .link {
          font-size: 12px;
        }
      }
    }

    > .row {
      .col {
        width: 50%;
        margin: 0;
        padding-left: 15px;
      }
    }

    .menu-container {
      > .col {
        padding: 0;
      }

      .menu-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
      }

      .menu-button {
        svg {
          width: 40px;
        }
      }

      > .account-container {
        width: 75%;
      }

      .menu {
        margin-top: 140%;
        box-shadow: 10px 10px 15px 10px rgba(0, 0, 0, 0.1), 10px 4px 6px -4px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
