@import '../../variables/color-palette.scss';
@import '../../variables/breakpoints.scss';

.introduction {
  transform: translate(0, 100%);
  background-color: white;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  min-height: 100px;
  height: fit-content;
  overflow: auto;

  > .row {
    margin-left: 0;
    margin-right: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .scroll-bar {
    padding-top: 11px;
  }

  .intro-title {
    padding-top: 19px;
    padding-bottom: 15px;

    p {
      font-size: 24px;
      margin: auto;
      text-align: center;
    }
  }

  .hidden-area {
    top: 0;
    padding: 0 50px;
    margin-left: 0;
    margin-right: 0;

    .row {
      display: flex;
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
    }

    .detailed-intro {
      .share-with-friend-container {
        position: relative;

        > div {
          display: flex;
          justify-content: center;

          img {
            width: 100%;
            margin: auto;
          }
        }

        h2 {
          position: absolute;
          bottom: 20px;
          font-weight: 700;
          font-size: 40px;
        }
      }

      .hidden-blockchain-text {
        margin-top: 24px;
      }
    }

    .tabs {
      margin-top: 80px;
      .tabs-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        background-color: #d9d9d9;
        margin-bottom: 42px;
        border-radius: 30px;
        padding: 0;

        > .col {
          padding: 0;
        }

        button {
          height: 40px;
          background-color: transparent;
          border: transparent;
          color: black;
          width: 100%;
          border-radius: 30px;
          justify-content: center;

          &.active {
            color: white;
            background-color: $primaryColor;
          }

          p {
            margin: 0;
          }

          &:hover {
            color: white;
            background-color: $primaryColor;
          }
        }
      }

      .tabs-container {
        .tab {
          display: none;
          padding-left: 0;
          padding-right: 0;

          &.active {
            display: flex;
          }

          .tab-content {
            width: 100%;
            justify-content: space-between;
            min-height: 400px;

            h2 {
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 30px;
            }

            .content-html {
              margin-top: 20px;
              flex-direction: column;
              display: flex;
              justify-content: center;
            }

            .content-img {
              margin: auto 0;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: $xxl) {
  .introduction {
    .hidden-area {
      padding: 0;

      .detailed-intro {
        .share-with-friend-container {
          h2 {
            font-size: 32px;
          }
        }
      }
    }
  }
}

@media (min-width: $lg) and (max-width: 1199px) {
  .introduction {
    .hidden-area {
      .detailed-intro {
        .share-with-friend-container {
          h2 {
            font-size: 36px;
          }
        }
      }

      .tabs {
        .tabs-buttons {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media (min-width: $xxl) and (max-width: 1600px) {
  .introduction {
    .hidden-area {
      .detailed-intro {
        .share-with-friend-container {
          h2 {
            font-size: 30px;
          }
        }
      }
    }
  }
}

@media (min-width: $md) and (max-width: 1024px) {
  .introduction {
    display: none;
  }
}

@media (max-width: $md) {
  .introduction {
    display: none;
    .intro-title {
      padding-right: 0;
      padding-top: 12px;
      padding-bottom: 9px;
      p {
        font-size: 20px;
        padding: 0;
      }
    }

    .hidden-area {
      padding: 0;

      .detailed-intro {
        padding-left: 0;
        padding-right: 0;

        .share-with-friend-container {
          h2 {
            font-size: 35px;
            bottom: 0;
          }
        }
        .hidden-blockchain-text {
          font-size: 12px;
          font-weight: 700;
        }
      }

      .tabs {
        margin-top: 5px;
        padding-left: 0;
        padding-right: 0;

        .tabs-buttons {
          margin-bottom: 5px;
          button {
            p {
              font-size: 12px;
            }
          }
        }

        .tabs-container {
          padding: 0;

          .tab {
            .tab-content {
              flex-direction: column-reverse;

              .content-html {
                margin-top: 10px;
                padding: 0;
              }

              .content-img {
                margin-bottom: 15px;

                padding: 0;

                img {
                  max-height: 400px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .introduction {
    display: none;
    .detailed-intro {
      .share-with-friend-container {
        h2 {
          font-size: 20px !important;
        }
      }
    }

    .tabs-container {
      .tab {
        .tab-content {
          .content-img {
            img {
              max-height: 200px !important;
            }
          }
        }

        .content-html {
          height: 250px !important;
        }
      }
    }

    .intro-title {
      padding-top: 5px;
      padding-bottom: 4px;
    }
  }
}
