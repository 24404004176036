.down-triangle {
  position: absolute;
  display: block;
  transform: translateX(50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #fff;
}
