@import '../variables/color-palette.scss';
@import '../variables/breakpoints.scss';

.recent-transfers {
  .content {
    max-width: 1033px;
    margin: auto;
    .title {
      color: #fff;
      font-size: 30px;
      font-weight: 700;
    }

    .main-row {
      background-color: rgba(255, 255, 255, 0.5);
      max-width: 1033px;
      border-radius: 50px;
      min-height: 580px;
      margin: auto;

      .transfers-list {
        position: relative;
        background-color: white;
        border-radius: 50px;
        min-height: 580px;
        .list-header {
          margin-top: 19px;
          margin-bottom: 12px;
          margin-right: 7.5%;
          margin-left: 7.5%;
          color: #478ff3;
          border-bottom: 2px solid $primaryColor;

          .icon {
            display: inline-block;
          }

          span {
            position: absolute;
            margin-left: 3px;
            padding-top: 2px;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            vertical-align: center;
          }
        }
        .items {
          width: 90%;
          max-width: 90%;
          margin-left: 7.5%;
          padding-right: 2.5%;
          height: 83%;
          overflow-y: auto;
          overflow-x: hidden; /* Prevent horizontal scrolling */
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap; /* add this line */
          .list-item {
            cursor: pointer;
            height: 46px;
            max-width: 100%; /* Ensure that items don't exceed their parent's width */
            margin-left: 0;
            padding: 0;
            width: 100%;
            .date {
              margin: auto;
              font-weight: 700;
              color: $primaryColor;
              font-size: 16px;
              vertical-align: center;
            }

            .files {
              margin: auto;
              font-weight: 700;
              color: $darkColor;
              font-size: 16px;
              vertical-align: center;
            }

            .people {
              margin: auto;
              vertical-align: left;
              font-size: 17px;
              .receiver-emails {
                position: relative;
                height: 32px;
                vertical-align: center;
                overflow: hidden;
                display: flex;
                background-color: #fff;
                border-radius: 16px;

                .email {
                  border-radius: 16px;
                  height: 22px;
                  width: 22px;
                  background-color: white;
                  justify-content: center;
                  margin: auto;
                  align-items: center;
                  display: flex;

                  &:first-child {
                    margin-left: 3px;
                  }
                }

                .email:not(:first-child) {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                }
              }

              .other-emails-count {
                margin: auto;
                color: $secondaryColor;
                margin-left: 40px;
              }
            }
          }

          .list-item:not(:last-child) {
            border-bottom: 1px solid $secondaryColor;
          }

          .list-item-selected {
            border-radius: 9px;
            background-color: $primaryColor;
            color: white;

            .date {
              color: white;
            }

            .files {
              color: white;
            }
          }

          .list-item-expired {
            opacity: 0.5;

            .date {
              color: $red;
            }
          }
        }
      }

      .transfer-detail {
        padding-top: 19px;
        max-width: 100%;
        .header {
          margin-left: 36px;
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          line-height: normal;
        }

        .people-info-row {
          margin-top: 16px;
          .header {
            color: #fff;

            .icon {
              display: inline-block;
            }

            span {
              margin-left: 7px;
              padding-top: 2px;
              font-size: 16px;
              font-weight: 700;
              font-style: normal;
            }
          }

          .people {
            height: 145px;
            .people-list {
              margin-left: 36px;
              margin-top: 12px;
              width: 85%;
              padding-top: 12px;
              border-radius: 20px;
              background-color: #fff;
              outline: 1px solid #d9d9d9;
              position: relative;
              height: 126px;
              overflow-y: auto;
              padding-left: 18px;

              &::-webkit-scrollbar-track {
                margin-bottom: 16px;
                margin-top: 16px;
              }

              .people-info {
                display: flex;
                .email-to {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 20px;
                  width: 20px;
                  height: 20px;
                  font-size: 10px;
                  font-weight: 700;
                  margin-top: 5px;
                  line-height: 20px;
                }

                .email {
                  font-weight: 700;
                  font-size: 12px;
                  display: inline-block;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  line-height: 10px;
                  margin-left: 10px;
                  margin-top: 10px;
                  width: 130px;
                  color: $darkColor;
                }
              }
            }
          }

          .info {
            height: 145px;
            width: 50%;
            .header {
              margin-left: 17px;
            }
            .details {
              margin-left: 17px;
              margin-top: 12px;
              padding-top: 12px;
              border-radius: 20px;
              background-color: #fff;
              outline: 1px solid #d9d9d9;
              position: relative;
              height: 126px;
              width: 85%;

              .row {
                max-width: 100%;
                padding-left: 10px;
                margin-left: 1px;
                margin-right: 3px;
                font-size: 13.5px;
                color: $darkColor;
                font-weight: 700;

                .detail {
                  padding-left: 0;
                  .icon {
                    display: inline-block;
                    width: 18px;
                  }

                  span {
                    margin-left: 5px;
                  }

                  .yes {
                    color: #38d047;
                    margin-right: 3px;
                  }

                  .no {
                    color: #ff4848;
                    margin-right: 3px;
                  }

                  .no-icon {
                    display: flex;
                    position: absolute;
                    padding-top: 8px;
                    padding-left: 22px;
                  }
                }
              }

              .row:not(:first-child) {
                margin-top: 19px;
              }
            }
          }
        }

        .files-row {
          margin-top: 16px;
          .header {
            margin-top: 16px;
            color: #fff;

            .icon {
              display: inline-block;
            }

            span {
              margin-left: 7px;
              padding-top: 2px;
              font-size: 16px;
              font-weight: 700;
              font-style: normal;
            }
          }

          .files {
            margin-left: 36px;
            margin-top: 12px;
            width: 87.5%;
            padding-top: 12px;
            border-radius: 20px;
            background-color: #fff;
            outline: 1px solid #d9d9d9;
            position: relative;
            height: 143px;
            overflow-y: auto;
            padding-left: 18px;

            &::-webkit-scrollbar-track {
              margin-bottom: 16px;
              margin-top: 16px;
            }

            .file-info {
              max-width: 100%;

              .name {
                margin-top: 4px;
                display: inline-block;
                white-space: nowrap;
                text-overflow: clip;
                overflow: hidden;
                font-size: 12px;
              }

              .size {
                margin-top: 4px;
                color: $secondaryColor;
                font-size: 12px;
              }

              .download {
                font-size: 12px;
                margin-top: 4px;
                color: $primaryColor;
                display: inline-block;
                cursor: pointer;
                span {
                  margin-left: 5px;
                }
              }
            }
          }
        }

        .buttons-row {
          margin-left: 10px;
          margin-right: 10px;
          margin-top: 34px;
          margin-bottom: 5%;

          .down-triangle {
            bottom: 33px;
            left: 10%;
            z-index: 999999;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            margin-bottom: 10px;
          }

          .delete-button {
            position: relative;

            .button {
              border-radius: 20px;
              width: 187px;
              height: 40px;
              border: 1px solid #d9d9d9;
              background: #fff;
              color: $red;
              font-size: 16px;
              font-weight: 600;
              box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
            }

            .delete-confirm-popup {
              position: absolute;
              flex-direction: column;
              background-color: white;
              display: flex;
              width: 321px;
              height: 138px;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              z-index: 999999;
              box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px, 0 10px 15px -3px rgba(0, 0, 0, 0.2),
                10px 4px 6px -4px rgba(0, 0, 0, 0.2);
              padding: 10px;
              border-radius: 20px;
              top: -150px;

              .delete-confirm-popup-header {
                width: 209px;
                height: 38px;
                text-align: center;

                .confirmation-text-span {
                  color: $darkColor;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: normal;
                  word-wrap: none;
                }
              }

              .button-container {
                margin-top: 20px;
                max-width: 320px;
                height: 40px;

                .btn-delete {
                  width: 130px;
                  height: 40px;
                  flex-shrink: 0;
                  color: $red;
                  background-color: #ffffff;
                  border: 1px solid #d9d9d9;
                  border-radius: 20px;
                  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                  margin-left: 20px;
                }

                .btn-cancel {
                  width: 130px;
                  height: 40px;
                  flex-shrink: 0;
                  color: #ffffff;
                  background-color: #478ff3;
                  border: 1px solid #d9d9d9;
                  border-radius: 20px;
                  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                }
              }
            }
          }

          .download-all-button {
            display: flex;
            justify-content: flex-end;
            .button {
              border-radius: 20px;
              width: 141px;
              height: 40px;
              border: 1px solid #d9d9d9;
              background: #fff;
              color: $darkColor;
              font-size: 16px;
              font-weight: 600;
              box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
            }
          }
        }

        .content {
          display: flex;
          width: 321px;
          height: 138px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;

          .text-container {
            color: #2c2c2c;
            text-align: center;
            font-family: 'Trebuchet MS';
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .button-container {
            margin-top: 20px;
            .col {
              padding: 0;
              margin: 0 5px;
            }

            .btn-cancel {
              color: #ffffff;
              background-color: #478ff3;

              &:hover {
                color: #ffffff;
                background-color: #478ff3;
              }
            }

            .btn-delete {
              color: #2c2c2c;
              background-color: #ffffff;
              box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.45);

              &:hover {
                color: #2c2c2c;
                background-color: #ffffff;
              }
            }

            button {
              height: 35px;
              width: 100%;
              font-size: 13px;
              border-radius: 25px;
              padding: 3px 10px;
              border: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $md) {
  .recent-transfers {
    .transfers-list {
      .items {
        margin-left: 5px !important;
        max-width: 100% !important;
        width: 100% !important;
      }
      .list-header {
        margin-left: 5px !important;
        margin-right: 5px !important;
      }
    }

    .transfer-detail {
      .header {
        margin-left: 0 !important;
      }

      .people {
        height: 200px !important;

        .people-list {
          margin-left: 0 !important;
          padding-left: 5px !important;
          height: 156px !important;
          width: 100% !important;
        }
      }

      .files-row {
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important;

        .files {
          margin-left: 0 !important;
          width: 100% !important;

          .file-info {
            > div {
              padding-left: 0px;
              width: auto !important;
            }
          }
        }
      }

      .info {
        .details {
          height: 156px !important;
          margin-left: 0 !important;
          width: 100% !important;
        }
      }

      .buttons-row {
        .delete-button {
          height: 100%;

          .button {
            width: 100% !important;
          }
        }
      }
    }
  }
}
