@import '../../variables/color-palette.scss';

.error-popup {
  display: flex;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 5vh;
  max-width: 681px;
  background: rgba(155, 168, 190, 0.5);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(75px);
  border-radius: 50px;
  z-index: 99999;
  .error-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding-left: 24px;
    padding-right: 13px;
    margin: auto;
    color: white;
  }

  .error-button {
    padding: 1%;
    max-width: 160px;
    margin-right: 25px;
    margin-left: auto;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    border: 0;
    width: 100%;
    height: 100%;
    font-weight: 700;
    color: $red;
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .error-popup {
    display: block;
    margin-left: 12px;
    margin-right: 12px;
    padding-right: 55px;
    .error-text {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      padding-left: 25px;
      padding-right: 25px;
    }
    .error-button {
      max-width: 100%;
      margin-right: 30px;
    }
  }
}
