@import '../../variables/color-palette.scss';

.account-header {
  text-align: end;
  min-width: 200px;
  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: var(--header-text-color);
    white-space: nowrap;
    margin-bottom: 0;
  }

  .link {
    font-size: 16px;
    color: var(--header-text-color);
    text-decoration: none;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    span {
      color: var(--header-text-color);
    }
    p {
      font-size: 16px;
      font-weight: var(--bs-body-font-weight);
      margin: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
