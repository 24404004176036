@import '../../variables/color-palette.scss';

.illustration {
  width: 100%;
  text-align: center;
  height: fit-content;

  img {
    max-width: 100%;
    object-fit: contain;
  }
  .illustration-header {
    color: $darkColor;
    font-weight: 550;
    font-size: 28px;
    line-height: 31px;
    margin-top: 39px;
  }
  .illustration-text {
    color: $darkColor;
    margin-top: 22px;
    font-weight: 200;
    font-size: 18px;
    line-height: 23px;
    margin-left: 10%;
    margin-right: 10%;
  }
}
