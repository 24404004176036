@import '../variables/color-palette.scss';

.error {
  margin-top: 40px;
  text-align: center;
  color: #fff;
  font-size: 20px;

  h1 {
    font-size: 40px;
    font-weight: 500;
  }

  p {
    font-size: 20px;
    font-weight: 500;
  }

  .buttons {
    width: fit-content;
    margin: auto;
    margin-top: 34px;

    .button {
      width: 222px;
      height: 40px;
      border-radius: 20px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    }

    .contact-us-button {
      border: 1px solid #d9d9d9;
      background: #fff;
      color: $darkColor;
    }

    .homepage-button {
      background: #478ff3;
    }
  }
}
