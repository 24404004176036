@import '../variables/color-palette.scss';

.signup {
  .main-row {
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 1033px;
    border-radius: 50px;
    min-height: 543px;
    margin: auto;
    .illustration-col {
      min-height: 543px;
      position: relative;
      background-color: white;
      height: 100%;
      border-radius: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .signup-col {
      padding-left: 2%;
      padding-right: 2%;
      .header {
        font-weight: 700;
        font-size: 25px;
        line-height: 29px;
        color: white;
        margin-top: 30px;
      }

      .form-checkbox {
        position: relative;
        padding-right: 20px;
        width: fit-content;
        .checkbox-label {
          position: relative;
          margin-left: 20px;
          color: white;
          font-weight: 700;
          font-size: 16px;
          justify-content: flex-start;
          span {
            text-decoration: underline 2px;
            text-underline-offset: 3px;
          }
        }

        .validation-error {
          z-index: 10;
          right: 0;
          top: 0;
          margin-right: -35px;
          .validation-text-container {
            right: -12px;
          }
        }
      }

      .sign-buttons {
        min-width: 100%;
      }
      .signin-button {
        margin-top: 40px;
        background: white;
        border-radius: 30px;
        width: 100%;
        height: 40px;
        color: $darkColor;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        border: none;
      }

      .signup-button {
        margin-top: 40px;
        background: $primaryColor;
        border-radius: 30px;
        width: 100%;
        height: 40px;
        color: white;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        border: none;
      }
    }

    .verify-column {
      margin-top: 150px;
      text-align: center;
      position: relative;

      h1 {
        margin-top: 69px;
        color: #ffffff;
        font-size: 25px;
      }

      p {
        margin-top: 17px;
        color: #ffffff;
        max-width: 300px;
        margin: auto;
        font-size: 16px;
      }

      button {
        margin-top: 60px;
        background: $primaryColor;
        border-radius: 30px;
        width: 210px;
        height: 40px;
        color: white;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        border: none;
      }
    }
  }
}
