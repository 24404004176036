@import '../variables/color-palette.scss';
@import '../variables/breakpoints.scss';

.landing {
  > .container {
    .logo-container {
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
      > div {
        display: flex;
        text-align: center;
        width: 320px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .logo-text {
        font-weight: 700;
        font-size: 78px;
        margin: 0;
        margin-left: 10px;
      }
    }

    .landing-container {
      margin: auto;
      cursor: pointer;
      border-radius: 3rem;
      width: 320px;
      height: 300px;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 40px 30px;
      text-align: center;
      box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 10px 4px 6px -4px rgb(0 0 0 / 0.1);
      --tw-shadow-color: #000;

      .landing-text {
        font-weight: 700;
        font-size: 25px;
        line-height: 29px;
        padding-top: 35px;
      }
      .landing-text-2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .error {
      box-shadow: $red 0px 1px 4px, $red 0px 0px 0px 3px;
    }

    .introduction-container {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    .miniscale {
      transform: scale(0.8);
      transition: 1s;
    }

    .minimized {
      opacity: 0;
      transition: 1s;
      z-index: -1;
    }

    .nminimized {
      transform: scale(1);
      opacity: 1;
      transition: 1s;
    }
  }
}

@media (max-width: $md) {
  .landing {
    .introduction-container {
      margin: 0;

      .col-md-12 {
        padding: 0;
      }
      .col-lg-6 {
        padding: 0;
      }
    }
  }
}
