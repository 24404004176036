@import '../../variables/color-palette.scss';

.logo-button {
  .up-triangle {
    margin-top: 15px;
    left: 150px;
  }

  .logo-container {
    position: absolute;
    width: 321px;
    padding: 20px 25px !important;
    left: 60px;
    margin-top: 35px;
    background-color: #ffffff;
    border-radius: 30px;
    border: 1px solid #d9d9d9;
    border-top: 0px;

    p {
      margin-bottom: 0;
    }

    .text {
      margin-top: 14px;
      font-weight: 700;
      font-size: 15px;
      line-height: 19px;
      color: $secondaryColor;
    }

    .content {
      .text-container {
        padding-right: 30px;
      }
      .button-container {
        margin-top: 20px;
        .col {
          padding: 0;
          margin: 0 5px;
        }

        .btn-cancel {
          color: #ffffff;
          background-color: #478ff3;

          &:hover {
            color: #ffffff;
            background-color: #478ff3;
          }
        }

        .btn-homepage {
          color: #2c2c2c;
          background-color: #ffffff;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.45);

          &:hover {
            color: #2c2c2c;
            background-color: #ffffff;
          }
        }

        button {
          height: 35px;
          width: 100%;
          font-size: 13px;
          border-radius: 25px;
          padding: 3px 10px;
          border: none;
        }
      }
    }
  }
}
