@import '../variables/color-palette.scss';

.verify {
  .container {
    position: relative;
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 1033px;
    min-width: 800px;
    height: 543px;
    border-radius: 50px;
    .main-row {
      height: 100%;
      .illustration-col {
        position: relative;
        background-color: white;
        height: 100%;
        max-width: 50%;
        border-radius: 50px;
      }
      .verify-column {
        margin-top: 150px;
        text-align: center;
        position: relative;

        h1 {
          margin-top: 69px;
          color: #ffffff;
          font-size: 25px;
        }

        p {
          margin-top: 17px;
          color: #ffffff;
          max-width: 300px;
          margin: auto;
          font-size: 16px;
        }

        button {
          margin-top: 60px;
          background: $primaryColor;
          border-radius: 30px;
          width: 210px;
          height: 40px;
          color: white;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          border: none;
        }
      }
    }
  }
}
