@import '../variables/breakpoints.scss';

.contact-us {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 65%;

  .image-container {
    display: flex;
    justify-content: flex-end;
  }

  div {
    img {
      width: 100%;
      max-width: 408px;
    }
  }

  .form-container {
    h2 {
      color: #478ff3;
      font-weight: 800;
      font-size: 40px;
    }

    p {
      color: #8892a4;
      font-size: 14px;
      font-weight: 900;
    }

    input,
    textarea {
      border: 1px solid #d9d9d9;
    }

    form {
      span {
        color: #8892a4;
      }
    }

    .send-container {
      margin-top: 20px;

      .social-container {
        a {
          margin-left: 20px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
      .btn-send {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        padding-left: 20px;
        padding-right: 20px;
        border: none;
        padding-left: 40px;
        padding-right: 40px;
        background-color: #478ff3;
      }
    }
  }
}

@media (max-width: $lg) {
  .contact-us {
    width: 90%;
  }
}

@media (min-width: $md) and (max-width: $xl) {
  .contact-us {
    .social-container {
      svg {
        width: 24px !important;
      }
    }
  }
}

@media (max-width: $md) {
  .contact-us {
    div {
      img {
        width: 60%;
        margin: auto;
      }
    }

    .form-container {
      margin-top: 20px;
      .text-container {
        margin: auto;
        h2 {
          text-align: center;
          font-size: 20px;
        }
        p {
          font-size: 13px;
          text-align: center;
        }
      }

      .send-container {
        margin-top: 10px;
        display: flex;
        flex-direction: column-reverse;

        button {
          width: 100%;
        }

        .social-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }
      }
    }
  }
}
