.animation {
  top: 0;
  background-color: white;
  background-image: url(https://safetp-assets.s3.eu-central-1.amazonaws.com/landing_pattern.png), url(https://safetp-assets.s3.eu-central-1.amazonaws.com/landing_pattern2.png);
  background-size: 2016px 1177px, 2016px 1177px;
  background-position: 0 bottom, 2016px bottom;
  background-repeat: repeat-x, repeat-x;
  position: absolute;
  z-index: -1;
  min-height: 100vh;
  min-width: 100%;
  // animation: move-left-1 15s linear infinite, move-left-2 30s linear infinite, reset 30s linear infinite;
}

// @keyframes move-left-1 {
//   0% {
//     background-position: 0 bottom, 2016px bottom;
//   }
//   100% {
//     background-position: -2016px bottom, 1008px bottom;
//   }
// }

// @keyframes move-left-2 {
//   0% {
//     background-position: 1008px bottom, 2016px bottom;
//   }
//   100% {
//     background-position: -2016px bottom, 0 bottom;
//   }
// }

// @keyframes reset {
//   0% {
//     background-position: -2016px bottom, 0 bottom;
//   }
//   100% {
//     background-position: 0 bottom, 2016px bottom;
//   }
// }
