@import '../../variables/color-palette.scss';

.sign-menu {
  padding: 20px 25px !important;
  right: -50px;

  .up-triangle {
    position: absolute;
    top: 0px;
    right: 65px;
    transform: translateX(50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #2c2c2c;
  }

  p {
    margin-bottom: 0;
  }

  .btn-close {
    display: block;
    position: absolute;
    right: 20px;
    border-radius: 50%;
    font-size: 15px;
    width: 10px;
    height: 10px;
    background-size: 60% 60%;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.9);

    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .content {
    .text-container {
      padding-right: 30px;
    }
    .button-container {
      margin-top: 20px;
      .col {
        padding: 0;
        margin: 0 5px;
      }

      .btn-signup {
        color: #ffffff;
        background-color: #476FE3;

        &:hover {
          color: #ffffff;
          background-color: #476FE3;
        }
      }

      .btn-signin {
        color: #2c2c2c;
        background-color: #ffffff;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.45);

        &:hover {
          color: #2c2c2c;
          background-color: #ffffff;
        }
      }

      button {
        width: 100%;
        font-size: 13px;
        border-radius: 25px;
        padding: 3px 10px;
        border: none;
      }
    }
  }
}
