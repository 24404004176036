@import '../variables/color-palette.scss';

.profile {
  .main-row {
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 1033px;
    border-radius: 50px;
    min-height: 580px;
    margin: auto;

    .change-background-container {
      background-color: white;
      border-radius: 50px;

      &.disabled {
        pointer-events: none;
      }

      .change-background {
        padding: 40px 20px;

        .image-container {
          height: 250px;

          img {
            width: 100%;
            height: 100%;
            max-height: 250px;
            object-fit: contain;
          }
        }

        h2,
        p {
          color: #8892a4;
        }

        p {
          font-weight: 500;
        }

        h2 {
          margin-bottom: 18px;
        }

        &.blurred {
          opacity: 50%;
        }

        .color-picker-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 20px;

          .color {
            width: 35px;
            height: 35px;
            border-radius: 40px;
            margin: 0 5px;

            &:hover {
              cursor: pointer;
            }

            &.active {
              outline: 2px solid #2c2c2c;
            }
          }
        }
      }
    }

    .profile-col {
      padding: 33px 46px;

      .header {
        margin-top: 22px;
        color: white;
      }

      .receive-emails-container {
        .checkbox-label {
          color: white;
          margin-left: 20px;
        }
      }

      .buttons {

        > div {
          display: flex;
          justify-content: space-between;
        }

        .save-settings-container {
          position: relative;

          .down-triangle {
            bottom: 55px;
            left: 25%;
            z-index: 999999;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
          }

          .confirm-page-chage-container {
            position: absolute;
            flex-direction: column;
            background-color: #fff;
            border-radius: 30px;
            left: -45%;
            bottom: 75px;
            width: 350px;
            padding: 10px;
            z-index: 999999;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px, 0 10px 15px -3px rgba(0, 0, 0, 0.2),
              10px 4px 6px -4px rgba(0, 0, 0, 0.2);

            h3,
            p {
              margin-bottom: 10px;
            }

            p {
              font-weight: 500;
            }

            h3 {
              font-weight: 900;
              font-size: 20px;
            }
            .row {
              margin: 0;
              width: 100%;
            }

            .btn-cancel-page-change {
              background-color: white;
              box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
              color: black;
            }

            .btn-confirm-page-change {
              background-color: #478ff3;
            }

            .buttons {
              margin-bottom: 10px;
            }
          }
        }

        button {
          border-radius: 25px;
          outline: none;
          border: none;
          width: 100%;
          margin-top: 30px;
          p {
            margin: 0;
          }

          &.btn-change-password {
            background-color: white;
            p {
              color: black;
            }
          }

          &.btn-save {
            background-color: #478ff3;
            p {
              color: white;
            }
          }
        }
      }

      .verification {
        position: relative;
        display: flex;
        flex-direction: row;
        .verification-input {
          position: relative;

          input {
            padding-left: 16px;
            display: flex;
            height: 40px;
            width: 100%;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 30px;
            border: 1px solid #d9d9d9;
            background: #fff;
            box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15) inset;
            color: $secondaryColor;
            font-size: 16px;
          }

          input::placeholder {
            color: #d9d9d9;
          }

          input::focus {
            border: 2px solid $primaryColor;
          }

          .countdown {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            color: $primaryColor;
            font-size: 16px;
            font-weight: 700;
            margin: auto;
            span {
              margin-right: 4px;
            }
          }
        }

        .verify-button {
          height: 40px;
        }
      }

      .email-container {
        position: relative;

        .validation-error.verification-required {
          display: flex;
          position: absolute;
          z-index: 9999;
          right: 15px;
          bottom: -3px;

          span {
            margin-right: 5px;
            font-weight: 900;
            color: red;
            text-decoration: underline;
          }
        }
      }

      .change-password-container {
        .header {
          .col {
            display: flex;
            align-items: center;

            h4 {
              color: white;
              margin-left: 15px;
              margin-bottom: 0;
            }
          }
        }

        .password-requirements-container {
          margin-top: 30px;
          margin-bottom: 45px;
          padding: 0 10px;

          .requirement {
            margin-top: 10px;

            span {
              margin-left: 10px;
              text-align: center;
              font-size: 13px;
              color: rgba(255, 255, 255, 1);

              &.blurred {
                color: rgba(255, 255, 255, 0.5);
              }
            }
          }
        }

        .button-container {
          margin-top: 30px;

          > div {
            display: flex;
            justify-content: space-between;
          }

          button {
            border-radius: 25px;
            outline: none;
            border: none;
            width: 100%;

            p {
              margin: 0;
            }

            &.btn-back {
              background-color: white;
              p {
                color: black;
              }
            }

            &.btn-save-password {
              background-color: #478ff3;
              p {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
