.menu-button {
  .up-triangle {
    top: 105%;
    right: 30px;
  }

  position: relative;

  > button {
    background: transparent;
    border: 0;
  }
}
