@import '../../variables/color-palette.scss';

.sending-process {
  background-color: #ffffff;
  border-radius: 50px;
  max-width: 820px;
  min-height: 580px;
  margin: auto;
  .progress {
    margin-top: 44px;
    padding-right: 10%;
    padding-left: 10%;
    height: max-content;
    background-color: transparent;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    .col {
      padding-top: 44px;
      align-items: center;
      margin: auto;
      flex-direction: column;
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      min-width: 0;
      justify-content: center;

      span {
        bottom: 0;
        margin-top: 14px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $secondaryColor;
      }
    }

    .progress-line {
      flex: 1;
      position: relative;
      top: 0;
    }
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    margin-top: 43px;
    padding-left: 10%;
  }

  .transfer-info {
    padding-right: 10%;
    padding-left: 10%;
    .col {
      .col-title {
        font-weight: 700;
        font-size: 16px;
        color: $secondaryColor;
      }

      .col-info {
        position: relative;
        outline: 1px solid #d9d9d9;
        border-radius: 20px;
        height: 145px;
        max-width: 295px;
        padding-left: 18px;
        padding-right: 15px;
        .list {
          position: relative;
          width: 100%;
          padding-top: 16px;
          max-height: 145px;
          overflow-y: auto;

          &::-webkit-scrollbar-track {
            margin-bottom: 16px;
            margin-top: 16px;
          }

          .file-info {
            display: flex;
            max-width: 100%;

            .file-name {
              font-weight: 700;
              font-size: 12px;
              display: inline-block;
              white-space: nowrap;
              text-overflow: clip;
              overflow: hidden;
              max-width: 55%;
              line-height: 14px;
              margin-left: 10px;
              margin-top: 5px;
              color: $darkColor;
            }

            .file-size {
              position: absolute;
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              margin-top: 5px;
              line-height: 14px;
              color: $secondaryColor;
              left: 75%;
              text-align: right;
            }
          }

          .people-info {
            display: flex;
            max-width: 100%;

            .email-to {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border-radius: 20px;
              width: 20px;
              height: 20px;
              font-size: 10px;
              font-weight: 700;
              margin-top: 5px;
              line-height: 20px;
            }

            .email {
              font-weight: 700;
              font-size: 12px;
              display: inline-block;
              white-space: nowrap;
              text-overflow: clip;
              overflow: hidden;
              line-height: 10px;
              margin-left: 10px;
              margin-top: 10px;
              color: $darkColor;
            }
          }
        }
      }
    }
  }

  .row {
    padding-right: 10%;
    padding-left: 10%;
    .password-info {
      margin-top: 13px;
      margin-bottom: 27px;
      border: 1px solid #d9d9d9;
      border-radius: 20px;
      height: 41px;
      max-width: 295px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 5px;
        color: $darkColor;
        font-weight: 700;
        font-size: 13.5px;
        line-height: 14px;
      }

      .yes {
        color: #38d047;
        margin-right: 3px;
      }

      .no {
        color: #ff4848;
        margin-right: 3px;
      }

      .no-icon {
        display: flex;
        padding-top: 8px;
      }
    }

    .expiration-info {
      margin-top: 13px;
      margin-bottom: 27px;
      border: 1px solid #d9d9d9;
      border-radius: 20px;
      height: 41px;
      max-width: 295px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 5px;
        color: $darkColor;
        font-weight: 700;
        font-size: 13.5px;
        line-height: 14px;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5%;
    .cancel-button {
      margin-top: 5%;
      width: 100%;
      height: 40px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      border-radius: 40px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: #2c2c2c;
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .confirm-button {
      margin-top: 5%;
      width: 100%;
      height: 40px;
      background: #478ff3;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      border-radius: 40px;
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }

  .upload-progress {
    position: relative;
    margin: auto;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    .progress-container {
      position: relative;
      margin-top: 66px;
      width: 188px;
      height: 188px;

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      .progress-bar {
        position: relative;
        animation: rotating 2s linear infinite;
        width: 100%;
        height: 100%;
      }

      .progress-percent {
        z-index: 100;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $primaryColor;
        font-size: 51.809px;
        font-style: normal;
        font-weight: 500;
      }
    }

    h1 {
      margin-top: 47px;
      width: 295px;
      text-align: center;
      color: $secondaryColor;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .transfer-done {
    position: relative;
    margin: auto;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      margin-top: 37px;
    }

    h1 {
      color: $darkColor;
      text-align: center;
      font-size: 25px;
      margin-top: 29px;
    }

    p {
      color: $darkColor;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      margin-top: 11px;
      span {
        color: $primaryColor;
        font-weight: 700;
      }
    }

    .buttons {
      width: 100%;
      margin: auto;
      margin-top: 36px;

      .button {
        margin-top: 5%;
        width: 100%;
        height: 40px;
        border-radius: 20px;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
      }

      .get-link-button {
        border: 1px solid #d9d9d9;
        background: #fff;
        color: $darkColor;
      }

      .homepage-button {
        background: #478ff3;
        border: 0px;
      }
    }
  }

  .verification {
    position: relative;
    margin: auto;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
      color: $secondaryColor;
      text-align: center;
      margin-top: 63px;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    p {
      margin-top: 17px;
      width: 430px;
      color: $secondaryColor;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
    }

    .verification-input {
      position: relative;
      width: 326px;
      margin-top: 22px;

      input {
        padding-left: 16px;
        display: flex;
        height: 40px;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 30px;
        border: 1px solid #d9d9d9;
        background: #fff;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15) inset;
        color: $secondaryColor;
        font-size: 16px;
      }

      input::placeholder {
        color: #d9d9d9;
      }

      input::focus {
        border: 2px solid $primaryColor;
      }

      .countdown {
        position: absolute;
        right: 9px;
        top: 50%;
        transform: translateY(-50%);
        color: $primaryColor;
        font-size: 16px;
        font-weight: 700;
        margin: auto;
        span {
          margin-right: 4px;
        }
      }
    }

    .buttons {
      width: 100%;
      margin-top: 108px;

      .button {
        width: 100%;
        height: 40px;
        border-radius: 20px;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
      }

      .resend-button-col {
        position: relative;
        .resend-button {
          border: 1px solid #d9d9d9;
          background: white;
          color: $darkColor;
        }
        .countdown {
          position: absolute;
          right: 15px;
          top: 55%;
          transform: translateY(-50%);
          color: $primaryColor;
          font-size: 13px;
          font-weight: 700;
          margin: auto;
          span {
            margin-right: 4px;
          }
        }
      }

      .back-button {
        border: 1px solid #d9d9d9;
        background: $red;
        border: 0px;
      }

      .next-button {
        background: $primaryColor;
        border: 0px;
      }
    }
  }
}
